import axios from 'axios'


const e_sign = axios.create({
    baseURL: "https://newsign.sunrise-resorts.com/app/v1/api/",
    
    headers: {
        'Accept': 'application/json',
        'Content-Type' : 'application/json' 
        
    },


})

// const payload_token = { 'merchant_token': localStorage.getItem('e_sign_token') }; 

export default {


    // ? E-sing Api
    login(item) {
        return e_sign.post('protalLogin', item);
    },
    e_sign_dashBoardCount() {
        return e_sign.post('updateDashboardCounter', { 'merchant_token': localStorage.getItem('e_sign_token') })
    },
    e_sign_in_singing_module_form(module_name){
        let payload = {
            'merchant_token': localStorage.getItem('e_sign_token'),
            module_name
        };
        return e_sign.post('portal_in_singing_module_form', payload );
    },
    e_sign_form_details(data){
        data['merchant_token'] = localStorage.getItem('e_sign_token')
        return e_sign.post('module_form_list_id/' + data.form_id , data)
    },
    e_sign_action_sign(data){
        data['merchant_token'] = localStorage.getItem('e_sign_token')
        data['portal'] = true
        return e_sign.post('portal_sign',data )
    },
    e_sign_action_reject(data) {
        data['merchant_token'] = localStorage.getItem('e_sign_token')
        data['portal'] = true
        return e_sign.post('portal_reject', data)
    },
    e_sign_action_reback(item) {
        item['merchant_token'] = localStorage.getItem('e_sign_token')
        return e_sign.post('reback' , );
    },
    e_sign_add_comment(data){
        data['merchant_token'] = localStorage.getItem('e_sign_token')
        data['portal'] = true
        return e_sign.post('add_comment' , data);
    }
}
